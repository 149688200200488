<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考试资料下载</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="考务名称" class="searchboxItem ci-full">
              <span class="itemLabel">考务名称:</span>
              <el-input
                  v-model="searchForm.activityName"
                  clearable
                  size="small"
                  placeholder="请输入考务名称"
              ></el-input>
            </div>
            <el-button
                class="bgc-bv"
                style="margin: 0 10px"
                round
                @click="getData()"
            >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  width="100px"
              />
              <el-table-column
                  label="考务名称"
                  align="center"
                  prop="activityName"
                  show-overflow-tooltip
                  min-width="160px"
              />
              <el-table-column
                  label="培训工种"
                  align="center"
                  prop="occupationName"
                  show-overflow-tooltip
                  min-width="160px"
              />
              <el-table-column
                  label="培训等级"
                  align="center"
                  prop="levelCode"
                  show-overflow-tooltip
                  min-width="120px"
              >
                <template slot-scope="{ row }">
                  {{
                    $setDictionary("ET_TRAIN_LEVEL", row.levelCode)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  label="考试时间"
                  align="center"
                  min-width="300"
              >
                <template slot-scope="{row}">
                  <template v-if="row.examList.length>0">
                    <div v-for="item in row.examList" :key="item.activityExamId">
                      {{$setDictionary('EA_EXAM_SUBJECT_CODE',item.subjectCode)}} :
                      {{item.startTime}} - {{item.endTime}}
                    </div>
                  </template>
                  <template v-else>
                    无
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                  label="考试人数"
                  align="center"
                  prop="personNum"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column label="操作" align="center" width="300px" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        size="mini"
                        style="padding: 0 5px"
                        @click="exportList(scope.row.activityId,'1',scope.row.isHaveNoCard)"
                    >下载准考证</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding: 0 5px"
                        @click="doorStickersConfigShow(scope.row.activityId,scope.row.isHaveNoCard)"
                    >门贴</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding: 0 5px"
                        @click="exportList(scope.row.activityId,'3',scope.row.isHaveNoCard)"
                    >桌贴</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding: 0 5px"
                        @click="exportList(scope.row.activityId,'4',scope.row.isHaveNoCard)"
                    >签到表</el-button
                    >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisibleDoorSticker"
            :close-on-click-modal="false"
            width="1200px"
            :before-close="handleCloseDoorSticker">
        <div>
            <el-form ref="doorStickerForm" :model="doorStickerForm" :inline="true">
                <el-form-item label="考务名称:" class="ci-full" style="width: 100%;margin-bottom: 10px;">
                    {{ doorStickerForm.activityName }}
                </el-form-item>
                <el-form-item label="考场名称" class="ci-full" prop="classroomId">
                    <el-select
                            style="width: 100px;"
                            size="mini"
                            v-model="doorStickerForm.classroomId"
                            @change="configEcho"
                    >
                        <el-option :key="item.classroomId" :label="item.name" :value="item.classroomId" v-for="(item,index) in classroomList"></el-option>

                    </el-select>
                </el-form-item>
                <el-form-item label="考场人数" class="ci-full" prop="maxNum">
                    <el-input-number
                            disabled
                            v-model="doorStickerForm.maxNum"
                            :precision="0"
                            :step="1"
                            :min="1"
                            :max="999"
                            :controls="false"
                            style="width: 60px;"
                            size="small"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="考场分成" class="ci-full" prop="layoutRow">
                    <el-input-number
                            v-model="doorStickerForm.layoutRow"
                            :precision="0"
                            :step="1"
                            :min="1"
                            :max="5"
                            :controls="false"
                            style="width: 60px;"
                            size="small"
                    ></el-input-number>
                    列
                </el-form-item>
                <el-form-item label="每列" class="ci-full" prop="layoutLine">
                    <el-input-number
                            v-model="doorStickerForm.layoutLine"
                            :precision="0"
                            :step="1"
                            :min="1"
                            :max="20"
                            :controls="false"
                            style="width: 60px;"
                            size="small"
                    ></el-input-number>
                    行
                </el-form-item>
                <el-form-item label="" class="ci-full">
                    <el-checkbox v-model="doorStickerForm.layoutIsRight">右侧开始排序</el-checkbox>
                </el-form-item>
                <el-form-item label="" class="ci-full">
                    <el-button type="success" size="mini" @click="generateDoorStickers(true)">生成门贴</el-button>
                </el-form-item>
                <el-form-item label="" class="ci-full">
                    <el-button type="warning" size="small" v-print="print" v-if="doorStickerShow">打印</el-button>
                </el-form-item>
            </el-form>
            <!--v-if="doorStickerShow"-->
            <div class="doorStickerBox" id="printArea" v-if="doorStickerShow">
                <div class="doorStickerTitle">
                    <h3>{{ config.classroomName }}</h3>
                    <!--<span>{{ doorStickerForm.ksTimeStr }}</span>-->
                    <div>讲台</div>
                </div>
                <div class="doorStickerList">
                    <div :class="
                            config.columnsNumber == 1 ?'doorStickerItem column-1':
                            config.columnsNumber == 2 ?'doorStickerItem column-2':
                            config.columnsNumber == 3 ?'doorStickerItem column-3':
                            config.columnsNumber == 4 ?'doorStickerItem column-4':
                            config.columnsNumber == 5 ?'doorStickerItem column-5': 'doorStickerItem'
                         "
                         v-for="(item,index) in dragonList"
                         :key="index">
                        <div class="doorStickerItemInner">
                            <template v-if="item.classroomUserId">
                                <div class="doorStickerText">
                                    <span>姓名：{{ item.userName }}</span>
                                    <span>座位号：{{ item.seatNumber }}</span>
                                </div>
                                <div class="doorStickerText">
                                    <span class="full">准考证号：{{ item.userCandidateNo }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <span style="font-size: 12px">空座</span>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive, validatePhone, validateIdCard } from "@/utils/common";
import {mapGetters} from "vuex";
export default {
  name: "testDataDownloadList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      searchForm:{
        activityName: "", //姓名
      },

      dialogVisibleDoorSticker: false,

      doorStickerForm: {
          activityName: '',//考务名称
          // ksTimeStr: '',//考务时间
          classroomId: '',//考场id
          name: '',//考场名称
          maxNum: undefined,//考场人数
          layoutRow: undefined,//列 1-5
          layoutLine: undefined,//行 1-20
          layoutIsRight: false,//右侧开始排序
      },
      classroomList: [],//考场列表
      userList: [],//考场学员
      doorStickerShow: false,//生成的门贴是否显示


      // 生成配置
      config: {
          columnsNumber: undefined,
          classroomName: ''
      },
      dragonList:[],
      // 打印配置
      print: {
          id: 'printArea',
          popTitle: '', // 打印配置页上方标题
          extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
          preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
          previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
          previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
          zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
          previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
          previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
          beforeOpenCallback() {}, // 开启打印前的回调事件
          openCallback() {}, // 调用打印之后的回调事件
          closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
          url: '',
          standard: '',
          extraCss: '',
      },
    };
  },
  created() {

  },

  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 门贴配置弹窗打开
    doorStickersConfigShow(activityId,isHaveNoCard){
        if(isHaveNoCard){
            this.$message.warning('请先生成准考证号！')
            return
        }
        let params = {
            activityId
        }
        this.$post("/biz/examination_affairs/download/doorPaperInfo", params, 3000, true, 6).then((res) => {
            console.log('res'+res)
            this.doorStickerForm.activityName = res.data.activityName
            // if(res.data.endTime.substring(0,10) == res.data.startTime.substring(0,10)){
            //   this.doorStickerForm.ksTimeStr = res.data.startTime.substring(11)  +'~'+  res.data.endTime.substring(11)
            // }else{
            //   this.doorStickerForm.ksTimeStr = res.data.startTime +'~'+ res.data.endTime
            // }
            this.classroomList = res.data.classroomList
            this.doorStickerForm.classroomId = res.data.lastClassroomId || res.data.classroomList[0].classroomId
            this.configEcho(this.doorStickerForm.classroomId)

            this.dialogVisibleDoorSticker = true
        })
    },
    // 根据考场id回显对应的配置
    configEcho(classroomId){
        let currentClassroom = this.classroomList.find(item=>item.classroomId == classroomId)
        this.doorStickerForm.classroomId = currentClassroom.classroomId
        this.doorStickerForm.name = currentClassroom.name
        this.doorStickerForm.maxNum = currentClassroom.maxNum || undefined
        this.doorStickerForm.layoutRow = currentClassroom.layoutRow || undefined
        this.doorStickerForm.layoutLine = currentClassroom.layoutLine || undefined
        this.doorStickerForm.layoutIsRight = currentClassroom.layoutIsRight
        this.userList = currentClassroom.userList || []

        if(currentClassroom.layoutRow||currentClassroom.layoutLine){
            this.doorStickerShow = false
            this.generateDoorStickers()
        }else{
            this.doorStickerShow = false
        }

    },
    // 生成门贴
    generateDoorStickers(needAjax){
        // 考场人数
        let totalPeople = this.doorStickerForm.maxNum?JSON.parse(JSON.stringify(this.doorStickerForm.maxNum)):''
        // 列数
        let columnsNumber = this.doorStickerForm.layoutRow?JSON.parse(JSON.stringify(this.doorStickerForm.layoutRow)):''
        // 行数
        let rowsNumber = this.doorStickerForm.layoutLine?JSON.parse(JSON.stringify(this.doorStickerForm.layoutLine)):''
        // 总座位数
        let totalDesk = this.doorStickerForm.layoutRow&&this.doorStickerForm.layoutLine?JSON.parse(JSON.stringify(this.doorStickerForm.layoutRow*this.doorStickerForm.layoutLine)):''
        // 右侧开始排序
        let rightStart = JSON.parse(JSON.stringify(this.doorStickerForm.layoutIsRight))
        // 学员列表
        let userList =  JSON.parse(JSON.stringify(this.userList))
        // console.log(totalDesk,totalPeople,columnsNumber,rowsNumber,rightStart)
        if(!columnsNumber){
            this.$message.error('请填写列数')
            return
        }
        if(!rowsNumber){
            this.$message.error('请填写行数')
            return
        }
        if(totalPeople>totalDesk){
            this.$message.error('您设置的座位数不能小于考场人数')
            return
        }

        // 一条龙初始化
        // userList
        this.dragonInit(columnsNumber,rowsNumber,rightStart,userList,needAjax)


    },
    // 一条龙初始化
    dragonInit(columnsNumber,rowsNumber,rightStart,userList,needAjax){
      // 列数 columnsNumber,行数 rowsNumber,是否从右侧开始 rightStart,学员列表 userList
      console.log(columnsNumber,rowsNumber,rightStart,userList)

      let totalDesk = columnsNumber*rowsNumber //总座位数
        // 如果学院人数小于总座位数
        if(userList.length < totalDesk){

            let nullList = Array(totalDesk - userList.length).fill({});
            // console.log(addList)
            userList = userList.concat(nullList)
        }
        // 把数组分成几等分
        let groupList = [] //分组列表
        let num = rowsNumber
        for(let i=0,len=userList.length;i<len;i+=num){
            groupList.push(userList.slice(i,i+num));
        }
        // console.log('groupList',groupList)
        // 双数列反转顺序
        groupList.forEach((e,i)=>{
          if((i%2) == 1){
              e = e.reverse()
          }
        })

        // 如果从右侧开始，翻转分组顺序
        if(rightStart){
            groupList = groupList.reverse()
        }
        console.log('groupList',groupList)


        let dragonList = [] //一条龙

        for (var i = 0; i < groupList[0].length; i++) {
            for (var j = 0; j < groupList.length; j++) {
                dragonList.push(groupList[j][i]);
            }
        }

        this.dragonList = dragonList
        this.config.columnsNumber = columnsNumber
        this.config.classroomName = JSON.parse(JSON.stringify(this.doorStickerForm.name))

        console.log('dragonList',dragonList)
        if(needAjax){
            let params = {
                layoutRow: this.doorStickerForm.layoutRow,
                layoutLine: this.doorStickerForm.layoutLine,
                layoutIsRight: this.doorStickerForm.layoutIsRight,
                classroomId: this.doorStickerForm.classroomId
            }
            this.$post("/biz/examination_affairs/download/updateClassroomLayout", params, 3000, true, 6).then((res) => {
                console.log('res'+res)
                this.doorStickerShow = true
            })
        }else{
            this.doorStickerShow = true
        }


    },
    // 门贴配置弹窗关闭
    handleCloseDoorSticker(){
        this.doorStickerForm.classroomId = ''
        this.doorStickerForm.name = ''
        this.doorStickerForm.maxNum =  undefined
        this.doorStickerForm.layoutRow =  undefined
        this.doorStickerForm.layoutLine =  undefined
        this.doorStickerForm.layoutIsRight = false
        this.userList = []
        this.dialogVisibleDoorSticker = false
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.activityName) {
        params.activityName = this.searchForm.activityName;
      }
      this.doFetch({
        url: "/biz/examination_affairs/download/pageList",
        params,
        pageNum,
      },true,6);
    },
    // 导出
    async exportList(activityId,type,isHaveNoCard) {
        if(isHaveNoCard){
            this.$message.warning('请先生成准考证号！')
            return
        }
      // type 1准考证 2汇总明细 3学员信息
      try {
        let url = ''
        if(type == '1'){
          url = '/biz/examination_affairs/download/admissionTicketDownload'
        }
        if(type == '2'){
          url = '/biz/examination_affairs/download/personnelDetails'
        }
        if(type == '3'){
          url = '/biz/examination_affairs/download/seatUserDownload'
        }
        if(type == '4'){
          url = '/biz/examination_affairs/download/candidateCheckinDownload'
        }
        const result = await this.$post(url, {
          activityId:activityId
        },3000,true,6);
        const arr = [...result.data];
        for (let item of arr) {
          if (!this.downloadItems.includes(item.taskId)) {
            this.$store.dispatch("pushDownloadItems", item.taskId);
          } else {
            this.$message.warning(`[${item.fileName}]已经申请下载`);
          }
        }
      } catch (e) {
        return;
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {

  },
};
</script>
<style lang="less" scoped>
/*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}
html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}
body{
  border: solid 1px blue ;
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
}
/*去除页眉页脚*/
.doorStickerBox{
  border: 1px solid #ddd;
  .doorStickerTitle{
    padding: 8px 20px;
    position: relative;
    &>h3{
      font-size: 22px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>span{
      font-size: 16px;
      position: absolute;
      height: 24px;
      top: 20px;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>div{
      margin-top: 8px;
      border: 1px solid #999;
      padding: 4px 0;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.doorStickerList{
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .doorStickerItem{

    &.column-1{
      width: 100%;
      min-width: 100%;
    }
    &.column-2{
      width: 50%;
      min-width: 50%;
    }
    &.column-3{
      width: 33.33%;
      min-width: 33.33%;
    }
    &.column-4{
      width: 25%;
      min-width: 25%;
    }
    &.column-5{
      width: 20%;
      min-width: 20%;
    }
    .doorStickerItemInner{
      margin: 0 4px 8px;
      padding: 10px;
      border: 1px solid #999;
      border-radius: 4px;
      min-height: 54px;
      .doorStickerText{
        display: flex;
        justify-content: space-between;
        span{
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:nth-child(1){
            max-width: calc(100% - 72px);
          }
          &:nth-child(2){
            width: 72px;
            text-align: right;
          }
          &.full{
            max-width: 100%;
          }
        }

      }
    }

  }
}
</style>
